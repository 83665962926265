<template>
  <nav
    id="nav"
    class="flex w-full content-center justify-between md:w-2/4 md:justify-center"
  >
    <div
      class="flex justify-between flex-1 md:flex-none items-center"
    >
      <div class="flex-1 md:flex-none md:mr-3">
        <div
          id="TituloInicio"
          class="block py-2 px-4 align-middle text-white no-underline "
        >
          <span class="pb-0 text-base text-white block inline-block opacity-0"
            >Bienvenido al Portal Gestion</span>
        </div>
      </div>
      
    </div>
  </nav>
</template>
<style scoped>
#nav {
  display: flex;
  align-items: center;
}
.router-link-active {
  border-bottom: solid 1px aliceblue;
}
</style>
<script>
import { mapState } from "vuex";

export default {
  name: "NavegacionInicio",
  components: {},
  data() {
    return {};
  },
  methods: {
    activar() {},
  },
  computed: {
    ...mapState(["esJefe"]),
  },
};
</script>
