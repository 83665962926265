import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    //Inicio
    login: false,
    token: "",
    display: "hidden",
    usuario: "",
    esJefe: "",
    logInicio: false,
    logCasos: false, 
    logCobranza: false,
    usuariosZonas: [],
    modalCargando: false,
    permisosUsuario: {},
    
    //Cobranza
    MotivosLLamadasCobranza: [],
    infoAgentesWolkvox: {},
    EncCobranza: [],
    modalChatObservComp: false,
    ChatObservaciones: {},
    modalInfoCliente: false,
    InfoCliente: {},
    ClientesFactPendientes: [],
    filtradoPendientes: {},
    filtrosPendientes: {},
    ClientesFactGestionadas: [],
    filtradoGestionadas: {},
    filtrosGestionadas: {},
    ClientesNuevasFact: [],
    UbicacionCobranza: "",
    resultadosConsultaTipificacion: [],
    filtradoNuevas: {},
    filtrosNuevas: {},
    modalCuestionario: false,
    estructuraCuestionario: {},

    //Casos
    PrioridadAlta: "",
    PrioridadMedia: "",
    PrioridadBaja: "",
    Areas: [],
    Cerrados: [],
    Abiertos: [],
    ayuda: false,
    auxayuda: false,
  },
  mutations: {
    //Inicio
    openNavInicio(state){
      state.logInicio = true
    },
    openNavCasos(state){
      state.logCasos = true
    },
    openNavCobranza(state){
      state.logCobranza = true
    },
    closeNavInicio(state){
      state.logInicio = false
    },
    closeNavCasos(state){
      state.logCasos = false
    },
    closeNavCobranza(state){
      state.logCobranza = false
    },
    openModalCargando(state){
      state.modalCargando = true
    },
    closeModalCargando(state){
      state.modalCargando = false
    },
    setPermisosUsuario(state, obj){
      state.permisosUsuario = obj
    },

    //Cobranza
    setMotivosLLamadasCobranza(state, obj){
      state.MotivosLLamadasCobranza = obj
    },
    setInfoAgentesWolkvox(state, obj){
      state.infoAgentesWolkvox = obj
    },
    setEncCobranza(state, obj){
      state.EncCobranza = obj
    },
    setUbicacionCobranza(state, val){ 
      state.UbicacionCobranza = val
    },
    setInfoAgentesWolkvoxCampActiva(state, obj){
      state.infoAgentesWolkvox.campActiva = obj
    },
    setInfoAgentesWolkvoxIdCampActiva(state, obj){
      state.infoAgentesWolkvox.idCampActiva = obj
    },
    openModalChatObservComp(state, chat){
      state.modalChatObservComp = true
      state.ChatObservaciones = chat
    },
    closeModalChatObservComp(state){
      state.modalChatObservComp = false
    },

    openModalInfoCliente(state, info){
      state.modalInfoCliente = true
      state.InfoCliente = info
    },
    closeModalInfoCliente(state){
      state.modalInfoCliente = false
    },

    setClientesFactPendientes(state, obj){
      state.ClientesFactPendientes = obj
    },
    setResultadosConsultaTipificacion(state, obj){
      state.resultadosConsultaTipificacion = obj
    },
    setfiltradoPendientes(state,obj){
      state.filtradoPendientes = obj
    },
    setfiltrosPendientes(state,obj){
      state.filtrosPendientes = obj 
    },
    limpiarfiltrosPendientes(state){
      state.filtrosPendientes = {} 
    },

    setClientesFactGestionadas(state, obj){
      state.ClientesFactGestionadas = obj
    },
    setfiltradoGestionadas(state,obj){
      state.filtradoGestionadas = obj
    },
    setfiltrosGestionadas(state,obj){
      state.filtrosGestionadas = obj 
    },
    limpiarfiltrosGestionadas(state){
      state.filtrosGestionadas = {} 
    },

    setClientesNuevasFact(state, obj){
      state.ClientesNuevasFact = obj
    },
    setFiltradoNuevas(state,obj){
      state.filtradoNuevas = obj
    },
    setFiltrosNuevas(state,obj){
      state.filtrosNuevas = obj 
    },
    limpiarFiltrosNuevas(state){
      state.filtrosNuevas = {} 
    },

    openModalCuestionario(state){
      state.modalCuestionario = true
    },
    closeModalCuestionario(state){
      state.modalCuestionario = false
    },
    setEstructuraCuestionario(state, obj){
      state.estructuraCuestionario = obj
    },

    //Casos, pasar a modulo!
    loadNavbar(state, user) {
      state.display = "block";
      state.usuario = user.usuario;
      state.esJefe = user.esJefe;
    },
    hiddenNavbar(state) {
      state.display = "hidden";
    },
    logueo(state, token) {
      state.token = token;
    },
    loadPrioridades(state, prioridades) {
      state.PrioridadAlta = prioridades.prioridadAlta;
      state.PrioridadMedia = prioridades.pririodadMedia;
      state.PrioridadBaja = prioridades.pririodadBaja;
    },
    loadAreas(state, areas) {
      state.Areas = areas;
    },
    loadCerrados(state, cerrados) {
      state.Cerrados = cerrados;
    },
    loadAbiertos(state, abiertos) {
      state.Abiertos = abiertos;
    },
    abrirAyuda(state){
      state.ayuda = true
      state.auxayuda = true
    },
    cerrarAyuda(state){
      state.ayuda = false
    },
  },

  actions: {},
  modules: {},
});
