import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  //Login
  {
    path: "/",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  
  //Menu Principal
  {
    path: "/Inicio",
    name: "Inicio",
    component: () => import("../views/Inicio.vue"),
  },

  //Gestion de Casos
  {
    path: "/Casos/Analitica",
    name: "Analitica",
    component: () => import("../views/Casos/Analitica.vue")
  },
  {
    path: "/Casos/MisCasos",
    name: "MisCasos",
    component: () => import("../views/Casos/MisCasos.vue"),
  },
  {
    path: "/Casos/NuevoCaso",
    name: "NuevoCaso",
    component: () => import("../views/Casos/NuevoCaso.vue"),
  },
  {
    path: "/Casos/GestionCaso/:id",
    name: "GestionCaso",
    component: () => import("../views/Casos/GestionCaso.vue"),
  },
  {
    path: "/Casos/Asignados",
    name: "Asignados",
    component: () => import("../views/Casos/Asignados.vue"),
  },
  {
    path: "/Casos/Solucionados",
    name: "Solucionados",
    component: () => import("../views/Casos/Solucionados.vue"),
  },
  {
    path: "/Casos/Reportes",
    name: "Reportes",
    component: () => import("../views/Casos/Reportes.vue"),
  },

  //Cobranza
  {
    path: "/Cobranza/Pendientes",
    name: "Cobranza_Pendientes",
    component: () => import("../views/Cobranza/Pendientes.vue"),
  },
  {
    path: "/Cobranza/Gestionadas",
    name: "Cobranza_Gestionadas",
    component: () => import("../views/Cobranza/Gestionadas.vue"),
  },
  {
    path: "/Cobranza/CuestionarioNuevas",
    name: "Cuestionario_Nuevas",
    component: () => import("../views/Cobranza/CuestionarioNuevas.vue"),
  },
  {
    path: "/Cobranza/Gestiones/:numFactura",
    name: "Cobranza_Gestiones",
    component: () => import("../views/Cobranza/Gestiones.vue"),
  },
  {
    path: "/Cobranza/Informes",
    name: "Cobranza_Informes",
    component: () => import("../views/Cobranza/InformesCobranza.vue"),
  },

  //Cartera
  
  {
    path: "/Cartera/PasePedidos",
    name: "Cartera_PasePedidos",
    component: () => import("../views/Cartera/PasePedidos.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
