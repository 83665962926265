<template>
  <div id="app" class="bg-gray-100">
    <div class=" fondo_cargando fixed top-0 h-screen w-screen" v-show="modalCargando == true">
        <div class=" cargando absolute w-32 h-32 mx-auto ">
            <img src="/img/cargando_JB.gif" alt="">
        </div>
    </div>
    <div
      :class="display"
      class="bg-gray-800 pt-2 md:pt-1 pb-1 px-1 mt-0 h-auto fixed w-full z-20 top-0"
    >
      <div>
        <div class="flex flex-wrap items-center">
          <div
            class="flex flex-shrink md:w-1/4 justify-center md:justify-start text-white"
          >
            <router-link class="w-36" to="/Inicio">
              <img src="./assets/logo.png" alt="" />
            </router-link>
          </div>
          <NavegacionInicio v-show="logInicio"  class="pt-2"/>
          <NavegacionCasos v-show="logCasos"  class="pt-2"/>
          <NavegacionCobranza v-show="logCobranza"  class="pt-2"/>
          
          <div
            class="flex w-full pt-2 content-center justify-between md:w-1/4 md:justify-end"
          >
            <ul
              class="list-reset flex justify-between flex-1 md:flex-none items-center"
            >
              <li class="flex-1 md:flex-none md:mr-3">
                <div class="relative inline-block">
                  <button
                    @click="toggleDD()"
                    class="drop-button text-white focus:outline-none"
                  >
                    <span class="pr-2"
                      ><i
                        class="fas fa-user-tie"
                        aria-role="presentation"
                        aria-label=""
                      ></i
                    ></span>
                    Hola, {{ usuario.split(" ")[0] }}
                    <svg
                      class="h-3 fill-current inline"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                      />
                    </svg>
                  </button>
                  <router-link to="/">
                    <div
                      @click="deleteUserLogged()"
                      id="myDropdown"
                      :class="open ? 'block' : 'hidden'"
                      class="dropdownlist absolute bg-gray-800 text-white right-0 mt-3 p-3 overflow-auto z-30"
                      style="width: max-content"
                    >
                      <div class="border border-gray-800"></div>
                      <a
                        href="#"
                        class="p-2 hover:bg-gray-800 text-white text-sm no-underline hover:no-underline block"
                        ><i class="fas fa-sign-out-alt fa-fw"></i> Cerrar
                        sesión</a
                      >
                    </div>
                  </router-link>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- <transition
        enter-class="opacity-0"
        enter-active-class="ease-out transition-medium"
        enter-to-class="opacity-100"
        leave-class="opacity-100"
        leave-active-class="ease-out transition-medium"
        leave-to-class="opacity-0"
      >
        <div
          @keydown.esc="isOpen = false"
          v-show="isOpen"
          class="z-10 fixed inset-0 transition-opacity"
        >
          <div
            @click="isOpen = false"
            class="absolute inset-0 bg-black opacity-50"
            tabindex="0"
          ></div>
        </div>
      </transition>
      <aside
        class="transform top-0 left-0 w-64 bg-gray-800 te fixed h-full overflow-auto ease-in-out transition-all duration-300 z-30"
        :class="isOpen ? 'translate-x-0' : '-translate-x-full'"
      >
        <span @click="isOpen = false" class="flex w-full items-center p-4">
          <div
            class="bg-gray-800 shadow-xl h-16  bottom-0 mt-12 relative h-auto z-10 w-48"
          >
            <div
              class="mt-12 w-48 fixed left-0 top-0 content-start text-left justify-between"
            >
              <ul class="list-reset flex flex-col py-3 px-2 text-left"></ul>
            </div>
          </div>
        </span>
      </aside> -->
      </div>
    </div>

    <router-view />
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import NavegacionInicio from "./components/NavegacionInicio.vue";
import NavegacionCasos from "./components/NavegacionCasos.vue";
import NavegacionCobranza from "./components/NavegacionCobranza.vue";
import auth from "../src/logic/auth";
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "../src/assets/css/scroll.css";
import Vue from "vue";
Vue.component("v-select", vSelect);

export default {
  name: "Home",
  components: { NavegacionInicio, NavegacionCasos, NavegacionCobranza },
  data() {
    return {
      isOpen: false,
      open: false,
    };
  },
  created() {
    this.open = false;
  },
  methods: {
    drawer() {
      this.isOpen = !this.isOpen;
    },

    toggleDD() {
      this.open = !this.open;
    },

    openNav() {
      document.getElementById("sideNavigation").style.width = "250px";
      document.getElementById("main").style.marginLeft = "250px";
    },
    closeNav() {
      document.getElementById("sideNavigation").style.width = "0";
      document.getElementById("main").style.marginLeft = "0";
    },

    ocultarBarra() {
      document.getElementById("nav-content").classList.toggle("hidden");
    },
    deleteUserLogged() {
      this.toggleDD();
      auth.deleteUserLogged();
    },
    ...mapMutations(["loadNavbar", "hiddenNavbar", "logueo"])
  },
  computed: {
    ...mapState(["display", "usuario", "logCasos", "logCobranza", "logInicio","modalCargando"]),
  },
};
</script>

<style>
body{
  background-color: rgb(243,244,246);
}
::-moz-selection { /* Code for Firefox */
  color: #fff;
  background: #1e3a8a;
}

::selection {
  color: #fff;
  background: #1e3a8a;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.dropbtn {
  font-size: 16px;
  border: none;
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 1px 12px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #e290a1;
  color: white;
}

.ddl:hover {
  background-color: #e290a1;
  color: white;
}

.dropdown-content li {
  display: table-header-group;
}
.dropdown-content li:hover {
  color: white;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #e290a1;
}

#imagotipo {
  fill: #ff4745;
}

.layout-row {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  flex-direction: row;
}

.layout-align-center-center {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-grid-row-align: center;
  align-items: center;
  -webkit-align-content: center;
  align-content: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  position: absolute;
  margin-top: 50%;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.agotado {
  background-color: yellow;
}

.colorBarraSuperior {
  background-color: #e290a1;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-number-input input:focus {
  outline: none !important;
}

.custom-number-input button:focus {
  outline: none !important;
}

.bg-principal-500 {
  background-color: #e290a1;
}

input[type="radio"]:checked + label span {
  background-color: #e290a1;

  box-shadow: 0px 0px 0px 2px white inset;
}

input[type="radio"]:checked + label {
  color: #e290a1;
}

input[type="radio"] + label span {
  transition: background 0.2s, transform 0.1s;
  border-radius: 0.5rem;
}

input[type="radio"] + label span:hover,
input[type="radio"] + label:hover span {
  transform: scale(1.2);
}

button#trash:hover {
  transform: scale(1.2);
}

.overlay {
  background-color: #efefef;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  bottom: 0px;
  right: 0px;
  opacity: 0.5; /* in FireFox */
  filter: alpha(opacity=50); /* in IE */
}
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  /* width: 120px; */
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: fixed;
  z-index: 10;
  bottom: 3px;
  right: -60px;
  padding: 6px;
  margin: 75px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: -28%;
  right: 44%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.scrollable-place {
  height: 1000px;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.float {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 25px;
  right: 25px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.my-float {
  margin-top: 12px;
}
.vertical-center {
  margin: 0;
  position: fixed;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.fondo_cargando{
  display: flex;
  background-color: #1e3b8b;
  z-index: 99;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
</style>
