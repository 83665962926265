import axios from "axios";
import Cookies from "js-cookie";

const ENDPOINT_PATH =
  "https://juanabapl.juanabonita.com.co:446/GestionCasosNetcore/Api/Token";
  //"https://localhost:44320/Api/Token";

export default {
  setUserLogged(userLogged) {
    //console.log(userLogged)
    Cookies.set("userLogged", userLogged, { expires: 10000 });
  },
  getUserLogged() {
    return Cookies.get("userLogged");
  },
  login(usuario, contraseña) {
    const user = { usuario: usuario, clave: contraseña };
    return axios.post(ENDPOINT_PATH, user);
  },
  deleteUserLogged() {
    Cookies.remove("userLogged");
  },
};
