<template>
  <nav
    id="nav"
    class="flex w-full content-center justify-between lg:w-2/4 lg:justify-center"
  >
    <ul
      class="list-reset flex justify-between flex-1 lg:flex-none items-center"
    >
      <li class="flex-1 lg:flex-none md:mr-3" v-if="permisosUsuario.cuestionarioNuevas_Cobranza == true">
        <router-link
          to="/Cobranza/CuestionarioNuevas"
          id="idCasos"
          class="block py-2 px-4 align-middle text-white no-underline "
        >
          <i class="fas fa-question-circle pr-3"></i>
          <span class="pb-0 text-base text-white block inline-block">Cuestionario Nuevas</span>
        </router-link>
      </li>
      <li class="flex-1 lg:flex-none md:mr-3">
        <router-link
          to="/Cobranza/Pendientes"
          id="idCasos"
          class="block py-2 px-4 align-middle text-white no-underline "
        >
          <i class="fas fa-calendar pr-3"></i
          ><span class="pb-0 text-base text-white block inline-block"
            >Cobranzas Pendientes</span
          >
        </router-link>
      </li>
      <li class="flex-1 lg:flex-none md:mr-3">
        <router-link
          to="/Cobranza/Gestionadas"
          id="idCasos"
          class="block py-2 px-4 align-middle text-white no-underline "
        >
          <i class="fas fa-hand-holding-usd pr-3"></i
          ><span class="pb-0 text-base text-white block inline-block"
            >Cobranzas Gestionadas</span
          >
        </router-link>
      </li>
      <li class="flex-1 lg:flex-none md:mr-3" v-if="permisosUsuario.informes_Cobranza == true">
        <router-link
          to="/Cobranza/Informes"
          id="idReportes"
          class="block py-2 px-4 align-middle text-white no-underline "
        >
          <i class="fas fa-copy pr-3 text-white"></i
          ><span class="pb-0 text-base block inline-block hover:text-white"
            >Informes</span
          >
        </router-link>
      </li>
    </ul>
  </nav>
</template>
<style scoped>
#nav {
  display: flex;
  align-items: center;
}
.router-link-active {
  border-bottom: solid 1px aliceblue;
}
</style>
<script>
import auth from "@/logic/auth";
import { mapState } from "vuex";

export default {
  name: "NavegacionCobranza",
  components: {},
  data() {
    return {

    };
  },
  methods: {
    activar() {},
  },
  created(){
     try {
      var user = auth.getUserLogged();
      user = JSON.parse(user);
      if (user.token == "" || user.token == undefined) {
        this.$router.push("/");
      } else {
        this.display = "block";
        this.usuario = user.usuario;
        this.idUsuarioAux = user.id;
        this.loadNavbar(user);
      }
    } catch (err) {
      this.$router.push("/");
    }
  },
  computed: {
    ...mapState(["esJefe","permisosUsuario"]),
  },
};
</script>
