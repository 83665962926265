<template>
  <nav
    id="nav"
    class="flex w-full content-center justify-between md:w-2/4 md:justify-center"
  >
    <ul
      class="list-reset flex justify-between flex-1 md:flex-none items-center"
    >
      <li class="flex-1 md:flex-none md:mr-3">
        <router-link
          to="/Casos/MisCasos"
          id="idCasos"
          class="block py-2 px-4 align-middle text-white no-underline "
        >
          <i class="fas fa-list pr-3"></i
          ><span class="pb-0 text-base text-white block inline-block"
            >Mis Casos</span
          >
        </router-link>
      </li>
      <li v-if="esJefe =='1'" class="flex-1 md:flex-none md:mr-3">
        <router-link
          to="/Casos/Asignados"
          id="idCasos"
          class="block py-2 px-4 align-middle text-white no-underline "
        >
          <i class="fas fa-exchange-alt pr-3"></i
          ><span class="pb-0 text-base text-white block inline-block"
            >Asignados</span
          >
        </router-link>
      </li>
      <li class="flex-1 md:flex-none md:mr-3">
        <router-link
          to="/Casos/Solucionados"
          id="idSolucionados"
          class="block py-2 px-4 align-middle text-white no-underline"
        >
          <i class="fas fa-tasks pr-3"></i
          ><span
            class="pb-0 text-base block text-white inline-block hover:text-white"
            >Solucionados</span
          >
        </router-link>
      </li>
      <li v-if="esJefe=='1'" class="mr-3">
        <router-link
          to="/Casos/Analitica"
          id="idAnalitica"
          class="block py-2 px-4 align-middle text-white no-underline "
        >
          <i class="fas fa-chart-pie pr-3 text-white"></i
          ><span class="pb-0 text-base block inline-block hover:text-white"
            >Analítica</span
          >
        </router-link>
      </li>
      <li v-if="esJefe=='0' || '1'" class="mr-3">
        <router-link
          to="/Casos/Reportes"
          id="idReportes"
          class="block py-2 px-4 align-middle text-white no-underline "
        >
          <i class="fas fa-copy pr-3 text-white"></i
          ><span class="pb-0 text-base block inline-block hover:text-white"
            >Reportes</span
          >
        </router-link>
      </li>
    </ul>
  </nav>
</template>
<style scoped>
#nav {
  display: flex;
  align-items: center;
}
.router-link-active {
  border-bottom: solid 1px aliceblue;
}
</style>
<script>
import { mapState } from "vuex";

export default {
  name: "NavegacionCasos",
  components: {},
  data() {
    return {};
  },
  methods: {
    activar() {},
  },
  computed: {
    ...mapState(["esJefe"]),
  },
};
</script>
